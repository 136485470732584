//
// Core: Layout
//

html.scroll-smooth {
  scroll-behavior: smooth;
}

html,
body,
.page {
  height: 100%;
  min-width: 1280px;
  overflow-y: hidden;
}

// General :: ScrollBar

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $gray-500;
}

// Page Setup

.page {
  position: relative;
  overflow: hidden;
  height: 100vh;

  .main-header {
    top: 0;
    right: 0;
    left: 0;
    float: none;
    position: fixed;
    height: $main-header-height;
    z-index: $zindex-main-header;
    background-color: $white;
    border-bottom: 1px solid $gray-300;

    .main-branding {
      margin-left: -$navbar-padding-x;
      margin-right: #{$grid-gutter-width / 2};
      margin-top: -$navbar-padding-y;
      margin-bottom: -$navbar-padding-y;
      width: $sidebar-width;
      padding: 10px #{$grid-gutter-width / 2};
    }
  }

  .main-content {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    overflow-y: auto;
  }

  .sidebar {
    position: fixed;
    z-index: $zindex-main-header - 1;
    top: 0;
    bottom: 0;
    left: 0;
    width: $sidebar-width;
    overflow-y: auto;
    display: none;
    border-right: 1px solid $gray-300;
  }

  &.layout-sidebar {
    .main-content {
      left: $sidebar-width;
    }

    .sidebar {
      display: block;
    }
  }

  &.layout-top-nav {
    .main-content {
      top: $main-header-height;
    }

    .sidebar {
      top: $main-header-height;
    }
  }

  .content-wrapper {
    position: relative;
    padding: 0;
    margin: 0;

    &.map-content-wrapper {
      height: 100%;
    }

    &.map-content {
      overflow: hidden;
    }
  }

  .page-header {
    padding: 16px #{$grid-gutter-width} 12px;
    margin-bottom: 5px;
    display: flex !important;
    justify-content: space-between !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;

    &.border {
      border: 0 !important;
      border-bottom: 1px solid $gray-300 !important;
    }

    &.shadow {
      box-shadow: 0px 0px 15px 0px rgba(25, 104, 252, 0.2) !important;
    }
  }

  .page-content {
    padding: 24px #{$grid-gutter-width};
  }
}

// Navbar
.navbar {
  .main-branding {
    width: $sidebar-width;
  }
}

// Modal
.modal-backdrop {
  z-index: 2040;
}

.modal {
  z-index: 2050;
}
