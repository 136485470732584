
/* --- Map Icons --- */

.map-icon-label .map-icon {
  display: block;
  font-size: 24px;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  white-space: nowrap;
}

.map-icon {
  width: 30px !important;
  height: 30px !important;

  &:before {
    content: " ";
    width: 24px;
    height: 24px;
    border-radius: 50% 50% 50% 0;
    border: 1px solid #2d3e50;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }

  &:after {
    content: " ";
    font-size: 10px;
    width: 12px;
    height: 12px;
    margin: 0 0 0 2px;
    position: absolute;
  }

  &.pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;

    &:after {
      content: "";
      border-radius: 50%;
      height: 40px;
      width: 40px;
      position: absolute;
      margin: -13px 0 0 -13px;
      animation: pulsate 1s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
      box-shadow: 0 0 1px 2px #00cae9;
      animation-delay: 1.1s;
    }

    // http://sassbreak.com/nested-keyframe-rules-sass/
    @keyframes pulsate {
      0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
      }
    }
  }
}

/* Map Icon :: Shapes */

.map-icon {
  &.square {
    border-radius: 0px;
  }

  &.square-smooth {
    border-radius: 4px;
  }

  &.circle {
    border-radius: 50%;
  }
}

/* Map Icon :: BG Color */

.map-icon {
  &.red {
    color: #ffffff;
    &:before {
      background-color: #f62222;
      border-color: #efefef;
    }
  }

  &.green {
    color: #000000;
    &:before {
      background-color: #0ec226;
      border-color: #333333;
    }
  }

  &.blue {
    color: #ffffff;
    &:before {
      background-color: #3278e9;
      border-color: #0196fa;
    }
  }

  &.yellow {
    color: #783333;
    &:before {
      background-color: #fffb3c;
      border-color: #2d3e50;
    }
  }

  &.violet {
    color: #0196fa;
    &:before {
      background-color: #642bb1;
      border-color: #0196fa;
    }
  }

  &.orange {
    color: #ffffff;
    &:before {
      background-color: #ff6c00;
      border-color: #000000;
    }
  }

  &.pink {
    color: #ffffff;
    &:before {
      background-color: #ea49c5;
      border-color: #2d3e50;
    }
  }

  &.gray {
    color: #ffffff;
    &:before {
      background-color: #808080;
      border-color: #2d3e50;
    }
  }

  &.lime {
    color: #ffffff;
    &:before {
      background-color: #00ff00;
      border-color: #2d3e50;
    }
  }

  &.olive {
    color: #000000;
    &:before {
      background-color: #808000;
      border-color: #2d3e50;
    }
  }

  &.teal {
    color: #ffffff;
    &:before {
      background-color: #008080;
      border-color: #2d3e50;
    }
  }

  &.crimson {
    color: #ffffff;
    &:before {
      background-color: #b0092b;
      border-color: #2d3e50;
    }
  }

  &.purple {
    color: #ffffff;
    &:before {
      background-color: #c659c6;
      border-color: #0a0b0c;
    }
  }
}

/*------- Font Awesome 5 Free content values http://astronautweb.co/snippet/font-awesome/ -------*/
// Custom Type Icons

.map-icon-default {
  &:after {
    content: " ";
    background: #e6e6e6;
    border-radius: 50%;
  }
}

.map-icon-colleges,
.map-icon-schools,
.map-icon-universities {
  transform: rotate(-45deg);
  &:after {
    content: "\f19c"; // fa-university
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-restaurant,
.map-icon-restaurants {
  transform: rotate(-45deg);
  &:after {
    content: "\f805"; // fa-burger
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-bar,
.map-icon-pub {
  transform: rotate(-45deg);
  &:after {
    content: "\f0fc"; // fa-mug-empty
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-building {
  transform: rotate(-45deg);
  &:after {
    content: "\f1ad"; // fa-building
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-place-of-worship {
  transform: rotate(-45deg);
  &:after {
    content: "\f67f"; // fa-place-of-worship
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-electronic-stores,
.map-icon-mobiles-electronics {
  transform: rotate(-45deg);
  &:after {
    content: "\f025"; // fa-headphone
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-sports {
  transform: rotate(-45deg);
  &:after {
    content: "\f44e"; // fa-football
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-innerwears,
.map-icon-clothes-shoes-accessories {
  transform: rotate(-45deg);
  &:after {
    content: "\f290"; // fa-bag-shopping
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-supermarkets,
.map-icon-fashion-stores-departmental-stores,
.map-icon-wholesale-grocery-markets,
.map-icon-wholesale-flea-markets {
  transform: rotate(-45deg);
  &:after {
    content: "\f54e"; // fa-store
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-malls,
.map-icon-shopping-mall,
.map-icon-shopping-complex,
.map-icon-shopping-centres {
  transform: rotate(-45deg);
  &:after {
    content: "\f07a"; // fa-cart-shopping
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-atm,
.map-icon-bank,
.map-icon-banks-atms {
  transform: rotate(-45deg);
  &:after {
    font-size: 13px;
    content: "\f156"; // fa-rupee-sign
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-finance-companies {
  transform: rotate(-45deg);
  &:after {
    font-size: 13px;
    content: "\f571"; // fa-file-invoice-dollar
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-beauty-stores,
.map-icon-perfume-beauty {
  transform: rotate(-45deg);
  &:after {
    font-size: 13px;
    content: "\f221"; // fa-venus
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-luxury-brands {
  transform: rotate(-45deg);
  &:after {
    font-size: 14px;
    margin-left: 3px;
    content: "\24"; // fa-doller-sign
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-cinema,
.map-icon-books-cinema {
  transform: rotate(-45deg);
  &:after {
    content: "\f030"; // fa-camera
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-motorcycles {
  transform: rotate(-45deg);
  &:after {
    content: "\f21c"; // fa-motorcycle
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-cars,
.map-icon-cars-motorcycles-spares {
  transform: rotate(-45deg);
  &:after {
    content: "\f1b9"; // fa-car
    font-size: 14px;
    margin-left: 0px;
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-home,
.map-icon-home-kitchen {
  transform: rotate(-45deg);
  &:after {
    font-size: 13px;
    margin-left: 0px;
    content: "\f015"; // fa-house
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-jewellery,
.map-icon-snowflake {
  transform: rotate(-45deg);
  &:after {
    content: "\f2dc"; // fa-snowflake
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-travel {
  transform: rotate(-45deg);
  &:after {
    content: "\f072"; // fa-plane
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-toys,
.map-icon-toys-babies {
  transform: rotate(-45deg);
  &:after {
    content: "\f1ae"; // fa-child
    font-size: 13px;
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-coworking-space,
.map-icon-corporate-companies {
  transform: rotate(-45deg);
  &:after {
    content: "\f0b1"; // fa-briefcase
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-hardware-stores {
  transform: rotate(-45deg);
  &:after {
    content: "\f7d9"; // fa-screwdriver-wrench
    margin-left: 0px;
    font-size: 13px;
    font-family: "Font Awesome 5 Free";
  }
}

.map-icon-tilt {
  transform: rotate(-45deg);
  &:after {
    content: "\f052"; // fa-eject
    font-family: "Font Awesome 5 Free";
  }

  &.map-icon:after {
    transform: rotate(0deg);
  }
}

/* --- Pop-Up classes --- */

.leaflet-popup-content-wrapper {
  border-radius: 2px;
}

.map-popup-college {
  .leaflet-popup-content-wrapper {
    border: 1px solid gray;
    border-left-width: 12px;
    background-color: #00ccbb;
    opacity: 0.8;
    color: #ffffff;
  }
}
